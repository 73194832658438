










































import { Component, Vue } from "vue-property-decorator";
import { BButton, BSpinner } from "bootstrap-vue";

@Component({
  components: {
    BSpinner,
    BButton
  },
})
export default class ConfirmRgpd extends Vue {
  loading: boolean = false;
  isConfirme: boolean = false;
  error: string | null = null;


  async confirmRgpd() {
    this.loading = true;
    await this.$http.ressifnet.dossiers
      .confirmRGPD(this.$route.params.id, this.$route.params.timestamp)
      .then(
        async (response: any) => {
          this.loading = false;
          this.isConfirme = true;
        },
        (error: any) => {
          this.loading = false;
          this.isConfirme = false;

          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            this.error = error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ]
          } else {
            this.error = error?.response?.data?.detail ?? error.message
          }
        }
      );
  }
}
